.app{
  background: #0C0C22;
  color: white; 
}

.navbar {
  background: #223F62;
  padding: 15px;
}

ul {
  list-style: none;
  margin: 0;
  padding:0;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
}
li:nth-child(1) { grid-column-start: 2; }
li:nth-child(2) { margin-left: auto; }


.navbar__button {
  color: white; 
  font-size: calc(10px + 2vmin);
  font-weight: 300;
  background: inherit;
  justify-content: center;
  outline: none;
  cursor: pointer;
  border:none;
  text-align: center;
  padding: 10px;
}

.navbar__button__random:hover {
  background: #223F62;
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.navbar__button__random {
  color: white; 
  font-size: calc(10px + 2vmin);
  font-weight: 300;
  cursor: pointer;
  border:none;
  background: #3A587C;
  border-radius: 20px;
  padding: 10px;
  margin-right: 30px;
}

img{
  display:block;
  width: auto;
  height: auto;
}

.mountain-container{
  margin-top: 3%;
  display:flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  align-items: center;
  text-align: center;
}

a{
  margin: 18px;
  color:white;
}


